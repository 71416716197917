<template>
  <b-container fluid class="communication-detail-page mb-6 mt-6">
    <b-row>
      <b-col>
        <div class="w-100">
          <!-- Tabs with card integration -->
          <b-card no-body>
            <b-tabs
              nav-wrapper-class="tabs-custom"
              active-nav-item-class="tabs-custom__active_nav-item"
              content-class="tabs-custom__content"
              active-tab-class="pl-0 pr-0"
              v-model="tabIndex"
              card
            >
              <b-tab>
                <template #title>
                  <span
                    class="status-communication"
                    :class="`status-communication-${form.statusName}`"
                    v-if="form.statusName"
                    >{{ form.statusName }}</span
                  >
                  <div class="d-flex align-items-end">
                    <h5 class="m-0 p-0 mr-3">1</h5>
                    <span>Nội dung thông báo</span>
                  </div>
                </template>
                <b-container fluid>
                  <b-row>
                    <b-col cols="8">
                      <b-row>
                        <b-col>
                          <basic-input
                            label="Mã thông báo"
                            placeholder="Mã thông báo"
                            name="title"
                            :required="true"
                            disabled
                            :value.sync="form.code"
                          ></basic-input>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <basic-input
                            label="Tiêu đề thông báo"
                            placeholder="Nhập tiêu đề thông báo"
                            name="title"
                            disabled
                            :required="true"
                            :value.sync="form.title"
                          ></basic-input>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <basic-input
                            label="Chủ đề thông báo"
                            placeholder="Nhập chủ đề thông báo sẽ được hiển thị ở màn hình push notification"
                            name="topic"
                            :required="true"
                            disabled
                            :value.sync="form.topic"
                          ></basic-input>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col>
                      <div class="picture-input-container">
                        <img
                          class="mx-auto d-block img-placeholder"
                          :src="form.image"
                          alt=""
                        />
                        <!-- <picture-input
                          ref="pictureInput"
                          width="228"
                          height="128"
                          @change="onChangePicture"
                          margin="16"
                          :prefill="form.image"
                          accept="image/*"
                          size="16"
                          disabled
                          buttonClass="btn btn-success"
                          :crop="false"
                          :removable="false"
                          :customStrings="customStrings"
                        >
                        </picture-input> -->
                        <div class="mt-3 text-center">
                          <span>Hình ảnh liên quan, tỷ lệ 16:9</span>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <basic-text-editors
                        label="Nội dung thông báo"
                        placeholder="Nhập nội dung thông báo"
                        name="content"
                        :required="true"
                        disabled
                        :value.sync="form.body"
                        :toolbarOptions="['link', 'image', 'video']"
                      >
                      </basic-text-editors>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <basic-select
                        label="Loại Message"
                        placeholder="--- Chọn loại message ---"
                        name="messageType"
                        :options="messageTypeOpts"
                        :solid="false"
                        disabled
                        :allowEmpty="false"
                        :changeValueByObject="true"
                        :required="true"
                        :value.sync="form.messageType"
                      />
                    </b-col>
                    <b-col>
                      <basic-select
                        label="Loại Redirect"
                        placeholder="--- Chọn loại redirect ---"
                        name="redirectPage"
                        :options="redirectOpts"
                        :solid="false"
                        disabled
                        :allowEmpty="false"
                        :changeValueByObject="true"
                        :value.sync="form.redirectType"
                      />
                    </b-col>
                    <b-col v-if="isSurveyMessage" cols="4">
                      <basic-select
                        label="Loại khảo sát"
                        placeholder="--- Chọn loại khảo sát ---"
                        name="surveyType"
                        :options="[]"
                        :required="true"
                        track-by="id"
                        value-label="name"
                        :allowEmpty="false"
                        :value.sync="form.surveyType"
                        :disabled="true"
                      />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <basic-input
                        label="Nội dung link redirect"
                        :required="true"
                        placeholder="Nội dung redirect hiển thị trong thông báo chi tiết"
                        name="hyperText"
                        disabled
                        :value.sync="form.hyperText"
                      ></basic-input>
                    </b-col>
                    <b-col>
                      <!-- <basic-select
                        v-if="form.redirectType == 1"
                        label="Mobile Page"
                        placeholder="--- Chọn page để redirect ---"
                        name="redirectPage"
                        :options="messageTypeOpts"
                        :solid="false"
                        :allowEmpty="true"
                        :required="true"
                        :value.sync="form.redirectPage"
                      /> -->
                      <basic-input
                        label="Web page Link"
                        :required="true"
                        disabled
                        placeholder="Nhập web link để redirect"
                        name="hyperLink"
                        :value.sync="form.hyperLink"
                      ></basic-input>
                    </b-col>
                    <b-col></b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <basic-radio
                        label="Thời gian gởi thông báo"
                        name="notificationPushType"
                        :labelCols="12"
                        :inline="true"
                        disableRadio
                        :options="timeOpts"
                        :required="true"
                        :value.sync="form.notificationPushType"
                      >
                      </basic-radio>
                    </b-col>
                    <b-col v-if="form.notificationPushType == 2">
                      <div>
                        <b-form-group
                          id="scheduleDate"
                          label="Ngày gởi"
                          class="required"
                        >
                          <date-picker
                            placeholder="Nhập ngày gởi"
                            input-class="form-control"
                            name="scheduleDate"
                            v-validate="'required'"
                            format="DD/MM/YYYY"
                            disabled
                            type="date"
                            value-type="timestamp"
                            :disabled-date="notBeforeToday"
                            :error="errors.first('scheduleDate')"
                            v-model="form.scheduleDate"
                          >
                          </date-picker>
                          <input
                            v-model="form.scheduleDate"
                            :error="errors.first('scheduleDate')"
                            v-show="false"
                            name="scheduleDate"
                            v-validate="'required'"
                          />
                        </b-form-group>
                      </div>
                    </b-col>
                    <b-col v-if="form.notificationPushType == 2">
                      <!-- <basic-date-picker
                        label="Giờ gởi"
                        placeholder="Nhập giờ gởi"
                        name="scheduleTime"
                        :required="true"
                        type="time"
                        format="hh:mm"
                        :value.sync="form.scheduleTime"
                      /> -->
                      <div>
                        <b-form-group
                          id="scheduleTime"
                          label="Giờ gởi"
                          class="required"
                        >
                          <date-picker
                            placeholder="Nhập giờ gởi"
                            input-class="form-control"
                            name="scheduleTime"
                            v-validate="'required'"
                            type="time"
                            disabled
                            value-type="timestamp"
                            format="HH:mm"
                            v-model="form.scheduleTime"
                          >
                            <template v-slot:icon-calendar>
                              <span class="svg-icon">
                                <inline-svg
                                  src="/media/svg/icons/Neolex/Time/icon.svg"
                                />
                              </span>
                            </template>
                          </date-picker>
                          <input
                            v-model="form.scheduleTime"
                            v-show="false"
                            name="scheduleTime"
                            disabled
                            :error="errors.first('scheduleTime')"
                            v-validate="'required'"
                          />
                          <span v-show="errors.first('scheduleTime')"></span>
                        </b-form-group>
                      </div>
                    </b-col>
                  </b-row>
                </b-container>
              </b-tab>
              <b-tab>
                <template #title>
                  <div class="d-flex align-items-end">
                    <h5 class="m-0 p-0 mr-3">2</h5>
                    <span>Đối tượng nhận thông báo</span>
                  </div>
                </template>
                <b-container fluid>
                  <b-row>
                    <b-col>
                      <h6>Thiết lập đối tượng bệnh nhân nhận thông báo</h6>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <basic-radio
                        name="notificationObject"
                        :labelCols="12"
                        :inline="false"
                        disableRadio
                        :options="notificationObjectOpts"
                        :required="true"
                        :value.sync="patientReceive.notificationPushTarget"
                      >
                      </basic-radio>
                    </b-col>
                  </b-row>
                  <template v-if="patientReceive.notificationPushTarget === 2">
                    <b-row>
                      <b-col>
                        <h6>Chọn các app users</h6>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col>
                        <div class="w-100 d-flex justify-content-between">
                          <h6>
                            {{
                              patientChoosens.length
                                ? patientChoosens.length
                                : 'Chưa có'
                            }}
                            bệnh nhân được nhận thông báo
                          </h6>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <template-table
                          :column="column"
                          :data="patientChoosens"
                          :tableAction="false"
                          :selectAction="false"
                          :searchAction="false"
                          :pagingAction="false"
                        >
                          <template v-slot:body="{ item }">
                            <td></td>
                            <td>
                              <div class="d-flex align-items-center mt-5">
                                <avatar
                                  v-if="item"
                                  size="40px"
                                  :text="item.fullName"
                                  :src="item.imageUrl && item.imageUrl.url"
                                  :rounded="true"
                                ></avatar>
                                <div class="d-flex flex-column ml-5">
                                  <p
                                    class="mb-0"
                                    style="
                                      font-weight: 600;
                                      font-size: 13px;
                                      color: #515356;
                                    "
                                  >
                                    {{ item.fullName }}
                                  </p>
                                  <p
                                    class="mt-2 mb-0"
                                    style="font-size: 12px; color: #888c9f"
                                  >
                                    Mã số: {{ item.code || 0
                                    }}{{
                                      item.membershipType
                                        ? `, ${item.membershipType}`
                                        : ', Thành viên Cơ Bản'
                                    }}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td>
                              {{ item.age }}
                            </td>
                            <td>
                              {{ item.gender }}
                            </td>
                            <td>
                              {{ $moment(item.date).format('DD/MM/YYYY') }}
                            </td>
                            <td>
                              {{ item.nation && item.nation.name }}
                            </td>
                            <td>
                              {{ item.province && item.province.name }}
                            </td>
                            <td>
                              {{ item.district && item.district.name }}
                            </td>
                            <td>
                              {{ item.ward && item.ward.name }}
                            </td>
                            <td>
                              <div style="max-width: 100px">
                                <user-state :isActive="item.active" />
                              </div>
                            </td>
                          </template>
                        </template-table>
                      </b-col>
                    </b-row>
                  </template>
                  <!-- <template v-if="notificationObject === 3">
                    <b-row>
                      <b-col>
                        <h6>Các nhóm Health Coach</h6>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col></b-col>
                    </b-row>
                  </template> -->
                  <template v-if="patientReceive.notificationPushTarget === 4">
                    <b-row>
                      <b-col>
                        <h6>Thiết lập điều kiện nhận thông báo</h6>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <label for="age">Độ tuổi</label>
                        <b-row>
                          <b-col>
                            <basic-input
                              name="startAge"
                              inputType="number"
                              disabled
                              :value.sync="patientReceive.ageStart"
                            ></basic-input>
                          </b-col>
                          <b-col>
                            <basic-input
                              name="endAge"
                              inputType="number"
                              disabled
                              :value.sync="patientReceive.ageEnd"
                            ></basic-input>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col>
                        <basic-select
                          label="Giới tính"
                          placeholder="--- Chọn giới tính ---"
                          name="gender"
                          :options="isGenderOpts"
                          :solid="false"
                          disabled
                          :value.sync="patientReceive.gender"
                          :allowEmpty="true"
                        />
                      </b-col>
                      <b-col>
                        <basic-select
                          label="Tình trạng bệnh"
                          placeholder="--- Chọn tình trạng bệnh ---"
                          name="condition"
                          disabled
                          :options="diabetesStateOptions"
                          :value.sync="patientReceive.diabetesState"
                          :solid="false"
                          :allowEmpty="true"
                        />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <basic-select
                          label="Tỉnh thành"
                          placeholder="--- Chọn tỉnh thành ---"
                          name="province"
                          :apiPath="'/Division/Provinces'"
                          :searchField="'searchTerm'"
                          :solid="false"
                          disabled
                          :value.sync="patientReceive.provinceId"
                          :allowEmpty="true"
                        />
                      </b-col>
                      <b-col>
                        <basic-select
                          label="Quận / huyện"
                          placeholder="--- Chọn quận / huyện ---"
                          name="district"
                          :apiPath="'/Division/Dictricts'"
                          :searchField="'searchTerm'"
                          :solid="false"
                          disabled
                          :value.sync="patientReceive.districtId"
                          :allowEmpty="true"
                        />
                      </b-col>
                      <b-col>
                        <basic-select
                          label="Phường xã"
                          placeholder="--- Chọn phường xã ---"
                          name="wards"
                          :apiPath="'/Division/Wards'"
                          :searchField="'searchTerm'"
                          :value.sync="patientReceive.wardId"
                          :solid="false"
                          disabled
                          :allowEmpty="true"
                        />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <basic-select
                          :value.sync="patientReceive.coaches"
                          :allowEmpty="true"
                          :solid="false"
                          name="coach"
                          label="Nhóm Coach"
                          placeholder="--- Chọn nhóm coach ---"
                          changeValueByObject
                          :options="[]"
                          multiple
                          disabled
                        />
                      </b-col>
                      <b-col>
                        <basic-select
                          :value.sync="patientReceive.clinics"
                          :allowEmpty="true"
                          :solid="false"
                          label="Bệnh viện / Phòng khám"
                          placeholder="--- Chọn bệnh viện / phòng khám ---"
                          name="clinics"
                          changeValueByObject
                          multiple
                          :options="[]"
                          disabled
                        />
                      </b-col>
                      <b-col>
                        <label for="">BMI</label>
                        <b-row>
                          <b-col>
                            <basic-select
                              placeholder="Từ"
                              name="bmiFrom"
                              :solid="false"
                              :allowEmpty="true"
                              :state="validateState('bmiFrom')"
                              :invalidFeedback="errors.first('bmiFrom')"
                              value-label="text"
                              track-by="value"
                              :options="[]"
                              data-vv-as="BMI từ"
                              changeValueByObject
                              v-model="patientReceive.bmi.from"
                              disabled
                              @input="selectBmi($event, 'from')"
                            />
                          </b-col>
                          <b-col>
                            <basic-select
                              label=""
                              placeholder="Đến"
                              name="bmiTo"
                              :solid="false"
                              :allowEmpty="true"
                              :state="validateState('bmiTo')"
                              :invalidFeedback="errors.first('bmiTo')"
                              value-label="text"
                              track-by="value"
                              :options="[]"
                              changeValueByObject
                              data-vv-as="BMI đến"
                              v-model="patientReceive.bmi.to"
                              disabled
                              @input="selectBmi($event, 'to')"
                            />
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="4">
                        <basic-select
                          :value.sync="patientReceive.interestTopics"
                          :allowEmpty="true"
                          :solid="false"
                          name="module"
                          label="Chủ đề quan tâm"
                          placeholder="--- Chọn chủ đề quan tâm ---"
                          changeValueByObject
                          multiple
                          :options="[]"
                          disabled
                        />
                      </b-col>
                      <b-col cols="4">
                        <basic-select
                          :value.sync="patientReceive.packages"
                          :allowEmpty="true"
                          :solid="false"
                          name="package"
                          label="Gói hiện tại"
                          placeholder="--- Chọn gói hiện tại ---"
                          changeValueByObject
                          multiple
                          :options="[]"
                          disabled
                        />
                      </b-col>
                    </b-row>
                  </template>
                </b-container>
              </b-tab>
            </b-tabs>
          </b-card>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<style lang="scss">
.communication-detail-page {
  .tabs-custom {
    .nav-tabs {
      margin-right: -0.125rem;
      margin-bottom: -2rem;
      margin-left: -0.125rem;
      border-bottom: 0;

      .nav-item {
        .nav-link {
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 20px;
          transition: none;

          h5,
          span {
            color: #888c9f;
          }
        }
        .nav-link:hover,
        .nav-link:focus {
          border: none;
          border-bottom: 3px solid #4bb2ab;
          h5,
          span {
            color: #008479;
          }
        }

        .nav-link.tabs-custom__active_nav-item {
          border: none;
          border-bottom: 3px solid #4bb2ab;
          h5,
          span {
            color: #008479;
          }
        }
      }
    }
  }

  .picture-input-container {
    .picture-input {
      .preview-container {
        background: #ffffff;
        border: 3px solid #ffffff;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        border-radius: 6px;

        .picture-preview {
          z-index: 100 !important;
          background-color: #f3f6f9 !important;
        }

        .picture-inner {
          border: none;
        }
      }
    }
  }
}
.status-communication {
  position: absolute;
  right: 27px;
  border-radius: 56px;
  padding: 2px 14px;
  color: #fff !important;
  &-Draft {
    background-color: #888c9f;
  }
  &-Active {
    background-color: #407bff;
  }
  &-Sent {
    background-color: #21a567;
  }
  &-Recall {
    background-color: #ff5756;
  }
}
</style>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { NOTI_MESS_TYPE } from './mixins/communicationForm';

export default {
  name: 'CommunicationDetail',
  components: {
    'date-picker': DatePicker,
  },
  data() {
    return {
      column: [
        {
          key: 'actionDropdown',
          label: '',
          sortable: false,
        },
        {
          key: 'fullName',
          label: 'Bệnh nhân',
          sortable: false,
        },

        {
          key: 'age',
          label: 'Độ tuổi',
          sortable: false,
        },
        {
          key: 'gender',
          label: 'Giới tính',
          sortable: false,
        },
        {
          key: 'date',
          label: 'Thời gian tạo',
          sortable: false,
        },
        {
          key: 'country',
          label: 'Quốc gia',
          sortable: false,
        },
        {
          key: 'province',
          label: 'Tỉnh Thành',
          sortable: false,
        },
        {
          key: 'district',
          label: 'Quận',
          sortable: false,
        },
        {
          key: 'wards',
          label: 'Huyện',
          sortable: false,
        },
        {
          key: 'isActive',
          label: 'Trạng Thái',
          sortable: false,
        },
      ],
      messageTypeOpts: [
        { name: 'Marketing', value: NOTI_MESS_TYPE.MARKETING },
        { name: 'Tạo tài khoản', value: NOTI_MESS_TYPE.CREATE_ACCOUNT },
        { name: 'Chúc mừng sinh nhật', value: NOTI_MESS_TYPE.HAPPY_BIRTHDAY },
        {
          name: 'Tham gia gói dịch vụ thành công',
          value: NOTI_MESS_TYPE.JOIN_SERVICE_SUCCESS,
        },
        { name: 'Phân vào nhóm', value: NOTI_MESS_TYPE.JOIN_GROUP },
        {
          name: 'Mục tiêu ngày - đầu ngày',
          value: NOTI_MESS_TYPE.TARGET_START_DAY,
        },
        {
          name: 'Mục tiêu ngày - cuối ngày',
          value: NOTI_MESS_TYPE.TARGET_END_DAY,
        },
        {
          name: 'Mục tiêu tuần - đầu tuần',
          value: NOTI_MESS_TYPE.TARGET_START_WEEK,
        },
        {
          name: 'Mục tiêu tuần - cuối tuần',
          value: NOTI_MESS_TYPE.TARGET_END_WEEK,
        },
        {
          name: 'Lịch hẹn huấn luyện viên - 30 phút',
          value: NOTI_MESS_TYPE.COACH_APPT_30_MINUTE,
        },
        {
          name: 'Lịch hẹn huấn luyện viên - 1 ngày',
          value: NOTI_MESS_TYPE.COACH_APPT_1_DAY,
        },
        { name: 'Activity', value: NOTI_MESS_TYPE.ACTIVITY },
        { name: 'Khảo sát', value: NOTI_MESS_TYPE.SURVEY },
      ],
      redirectOpts: [
        { name: 'Web Page Redirect', value: 1 },
        { name: 'App  Redirect', value: 2 },
      ],
      timeOpts: [
        { name: 'Gởi ngay lập tức', value: 1 },
        { name: 'Gởi theo thời gian', value: 2 },
      ],
      notificationObjectOpts: [
        { name: 'Gởi thông báo cho toàn bộ bệnh nhân', value: 1 },
        { name: 'Gởi thông báo cho từng bệnh nhân', value: 2 },
        // {
        //   name: 'Gởi thông báo cho các bệnh nhân thuộc các nhóm Health Coach',
        //   value: 3,
        // },
        { name: 'Gởi thông báo cho các bệnh nhân theo điều kiện ', value: 4 },
      ],
      isGenderOpts: [
        { id: 1, name: 'Nam' },
        { id: 2, name: 'Nữ' },
      ],
      diabetesStateOptions: [
        { name: 'Tiền tiểu đường', id: 1 },
        { name: 'Loại 1', id: 2 },
        { name: 'Loại 2', id: 3 },
      ],
      tabIndex: 0,
      notificationObject: 1,
      form: {
        code: 'TB123',
        title: 'test',
        topic: 'topic',
        image: null,
        body: 'body',
        hyperText: 'hyperText',
        hyperLink: 'hyperLink',
        redirectPage: 'redirectPage',
        redirectType: { name: 'Web Page Redirect', value: 1 },
        messageType: { name: 'Maketing', value: 1 },
        notificationPushType: 1,
        scheduleDate: null,
        scheduleTime: null,
        surveyType: null,
      },

      patientReceive: {
        communicationId: null,
        notificationPushTarget: 1,
        ageStart: null,
        ageEnd: null,
        gender: null,
        diabetesState: null,
        nationId: null,
        provinceId: null,
        districtId: null,
        wardId: null,
        coaches: [],
        clinics: [],
        interestTopics: [],
        packages: [],
        topic: null,
        bmi: {
          from: {
            text: '0',
            value: '0',
          },
          to: {
            text: '0',
            value: '0',
          },
        },
      },
      patientChoosens: [],
      isEdit: false,
    };
  },

  computed: {
    timestamp: {
      get() {
        return new Date(this.form.scheduleTime).getTime();
      },
      set(val) {
        this.form.scheduleTime = new Date(val);
      },
    },
    customStrings: {
      get() {
        return {
          drag: `<div style="
            height: 28px;
            width: 28px;
            background-color: #fff;
            box-shadow: -1px 2px 10px rgba(0, 0, 0, 0.2);
            margin: auto;"
            class="d-flex align-items-center justify-content-center rounded-circle">
            <span>
            <img src="/media/svg/icons/Neolex/Basic/edit-2.svg" alt="Edit Icon" class="svg-icon" width="14px" height="14px" />
            </span>
            </div>`,
          change: 'Đổi hình ảnh',
          remove: 'Xóa hình ảnh',
        };
      },
    },
    isSurveyMessage() {
      return (
        this.form.messageType &&
        this.form.messageType.value === NOTI_MESS_TYPE.SURVEY
      );
    },
  },
  methods: {
    notBeforeToday(date) {
      return date < new Date();
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },

    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }
        const formData = new FormData();
        const conditionSend = this.patientReceive.notificationPushTarget;
        this.form.image && formData.append('image', this.form.image);
        this.form.code && formData.append('code', this.form.code);
        this.form.title && formData.append('title', this.form.title);
        this.form.topic && formData.append('topic', this.form.topic);
        this.form.body && formData.append('body', this.form.body);
        this.isEdit && formData.append('id', this.form.id);
        this.form.messageType &&
          formData.append('messageType', this.form.messageType.value);
        this.form.redirectType &&
          formData.append('redirectType', this.form.redirectType.value);
        this.form.notificationPushType &&
          formData.append(
            'notificationPushType',
            this.form.notificationPushType,
          );
        this.form.hyperText &&
          formData.append('hyperText', this.form.hyperText);
        this.form.hyperLink &&
          formData.append('hyperLink', this.form.hyperLink);
        if (this.form.notificationPushType == 2) {
          this.form.scheduleDate &&
            formData.append('scheduleDate', this.form.scheduleDate / 1000);

          this.form.scheduleTime &&
            formData.append('scheduleTime', this.form.scheduleTime / 1000);
        }
        this.patientReceive.notificationPushTarget &&
          formData.append(
            'notificationPushTarget',
            this.patientReceive.notificationPushTarget,
          );
        if (conditionSend == 4) {
          this.patientReceive.ageStart &&
            formData.append(
              'patientReceive.appPatientFilter.ageStart',
              this.patientReceive.ageStart,
            );
          this.patientReceive.ageEnd &&
            formData.append(
              'patientReceive.appPatientFilter.ageEnd',
              this.patientReceive.ageEnd,
            );
          this.patientReceive.gender &&
            formData.append(
              'patientReceive.appPatientFilter.gender',
              this.patientReceive.gender,
            );
          this.patientReceive.diabetesState &&
            formData.append(
              'patientReceive.appPatientFilter.diabetesState',
              this.patientReceive.diabetesState,
            );
          this.patientReceive.provinceId &&
            formData.append(
              'patientReceive.appPatientFilter.provinceId',
              this.patientReceive.provinceId,
            );
          this.patientReceive.districtId &&
            formData.append(
              'patientReceive.appPatientFilter.districtId',
              this.patientReceive.districtId,
            );
          this.patientReceive.wardId &&
            formData.append(
              'patientReceive.appPatientFilter.wardId',
              this.patientReceive.wardId,
            );
        }
        if (conditionSend == 2) {
          this.patientChoosens &&
            formData.append(
              'patientReceive.patientChoosens',
              JSON.stringify(this.patientChoosens.patientId),
            );
        }
        if (this.$route.params.form_type == 'edit') {
          this.$api
            .put(`Communication/Input`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then(() => {
              this.$toastr.s('Create success', 'SUCCESS');
              this.$router.push({ path: '/communication' });
            })
            .catch((error) => {
              this.$toastr.e(error.message, 'ERROR');
            })
            .finally(() => {
              this.$store.commit('context/setLoading', false);
            });
        } else {
          this.$api
            .post(`Communication/Input`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then(() => {
              this.$toastr.s('Create success', 'SUCCESS');
              this.$router.push({ path: '/communication' });
            })
            .catch((error) => {
              this.$toastr.e(error.message, 'ERROR');
            })
            .finally(() => {
              this.$store.commit('context/setLoading', false);
            });
        }
      });
    },
    onChangePicture() {
      this.form.image = this.$refs.pictureInput.file;
    },
    nextTab() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 200);
          return;
        } else {
          this.tabIndex = 1;
        }
      });
    },
    backTab() {
      this.tabIndex = 0;
    },
    resetRequest() {
      // Reset Form Data
    },
    async loadData() {
      try {
        let { data } = await this.$api.get(
          `Communication/${this.$route.params.id}`,
        );
        this.form = data;
        this.form.scheduleDate = data.scheduleDate * 1000;
        this.form.scheduleTime = data.scheduleTime && data.scheduleTime * 1000;
        this.patientReceive.notificationPushTarget =
          data.notificationPushTarget;
        this.form.image = data.imageUrl?.url;
        this.form.messageType = this.messageTypeOpts.find(
          (e) => e.value == data.messageType,
        );
        this.patientChoosens = data.patientReceives;
        data.bmiStart &&
          (this.patientReceive.bmi.from = {
            text: `${data.bmiStart}`,
            value: `${data.bmiStart}`,
          });
        data.bmiEnd &&
          (this.patientReceive.bmi.to = {
            text: `${data.bmiEnd}`,
            value: `${data.bmiEnd}`,
          });
        this.patientReceive.ageStart = data.ageStart;
        this.patientReceive.ageEnd = data.ageEnd;
        this.patientReceive.diabetesState = this.diabetesStateOptions.find(
          (e) => e.id == data.diabetesStatus,
        );
        this.patientReceive.gender = this.isGenderOpts.find(
          (e) => e.id == data.gender,
        );
        this.patientReceive.coaches =
          data.trainingGroups?.map((el) => ({
            name: el.name,
            id: el.trainingGroupId,
          })) || [];
        this.patientReceive.clinics =
          data.hospitals?.map((el) => ({ name: el.name, id: el.hospitalId })) ||
          [];
        this.patientReceive.interestTopics =
          data.lessonModules?.map((el) => ({
            name: el.name,
            id: el.lessonModuleId,
          })) || [];
        this.patientReceive.packages =
          data.packages?.map((el) => ({ name: el.name, id: el.packageId })) ||
          [];

        this.patientReceive.provinceId = data.province.name && data.province;
        this.patientReceive.districtId = data.district.name && data.district;
        this.patientReceive.wardId = data.ward.name && data.ward;
        this.form.redirectType = this.redirectOpts.find(
          (e) => e.value == data.redirectType,
        );
        this.form.surveyType = data.survey;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi!',
          msg: error,
        });
      }
    },
  },
  created() {
    this.isEdit = this.$route.params.form_type == 'detail';
    if (this.$route.params.id) {
      this.loadData();
    }
  },
  updated() {},
};
</script>
<style lang="scss" scoped>
.img-placeholder {
  width: 228px;
  height: 128px;
  background-color: #dedede;
  object-fit: cover;
}
</style>
